import { useEffect, useState } from "react";
import { getEnv } from "../../helpers/getEnv";

export function useEnv() {
  const [env, setEnv] = useState({
    isDevelopment: false,
    isProduction: false,
    isTest: false,
  });

  useEffect(() => {
    const result = getEnv();

    setEnv(result);
  }, []);

  return env;
}
