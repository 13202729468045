import React from "react";
import styles from "./Gallery.module.css";
import {Image} from "../image/Image";

export default function Gallery({ files }: { files: File[] }) {
  const length = files.length;
  const isEmpty = !length || length === 0;

  if (isEmpty) {
    return null;
  }

  return (
    <section className={styles.gallery}>
      {files.map((file, index) => <Image key={`${file.name}_${index}`} file={file} gridStyle={length}/>)}
    </section>
  );
}
