import { useSelector } from "react-redux";
import Gallery from "../gallary/Gallery";
import { processSelector } from "../../../ImageProcess";
import { useMock } from "../../hooks/useMock/useMock";
import { isMockImages } from "../../../../redux/selectors/isDevelopment";

export default function ResultImages() {
  let { result } = useSelector(processSelector);
  const isMock = useSelector(isMockImages);
  const { mockFiles, isDevelopment } = useMock();

  return <Gallery files={isDevelopment && isMock ? mockFiles : result} />;
}
