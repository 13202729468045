import { Observable } from "./Observable";

export enum LogLevels {
  info = "info",
  warn = "warn",
  error = "error",
}

export interface Log {
  message: string;
  level: LogLevels | keyof typeof LogLevels;
  timestamp: number;
}

class Logger extends Observable {
  private static instance: Logger;
  private logs: Log[] = [];

  constructor() {
    super();

    if (!Logger.instance) {
      Logger.instance = this;
      this.addLog("Logger initialized", LogLevels.info);
    }
    this.addLog("Logger already initialized", LogLevels.warn);

    return Logger.instance;
  }

  private getTimestamp() {
    return Math.floor(Date.now() / 1000);
  }

  public addLog(
    message: string,
    level: LogLevels | keyof typeof LogLevels = LogLevels.info
  ) {
    if (typeof level === "string") {
      const enumValue = LogLevels[level];

      if (enumValue) {
        level = enumValue;
      } else {
        level = LogLevels.info;
      }
    }

    const timestamp = this.getTimestamp();
    const newLog = { message, level, timestamp };
    this.logs = [...this.logs, newLog];
    this.broadcastUpdate(this.logs);
  }

  public read() {
    return this.logs;
  }

  public clear() {
    this.logs = [];
  }

  public printToConsole() {
    for (const log of this.logs) {
      console[log.level](`[${log.timestamp}] [${log.level}] ${log.message}`);
    }
  }

  private *filterLogsGen(levelFilter: LogLevels) {
    for (const log of this.logs) {
      if (log.level === levelFilter) {
        yield log;
      }
    }
  }

  public readByLevel(levelFilter: LogLevels) {
    const filterLogsGen = this.filterLogsGen(levelFilter);
    let result = filterLogsGen.next();
    const logs: Log[] = [];

    while (!result.done) {
      logs.push(result.value);
      result = filterLogsGen.next();
    }

    return logs;
  }
}

// Ensure the singleton nature by freezing the instance
const logger = new Logger();

export default logger;
