export const calculateCurrentAngle = (
  quality: number,
  minAngle: number,
  maxAngle: number,
  isClockwise: boolean,
  isIncreasing: boolean = true
) => {
  const normalizedQuality = isIncreasing
    ? (quality - 0.1) / 0.9
    : (1 - quality) / 0.9;

  let angleDifference;

  if (isClockwise) {
    angleDifference =
      maxAngle >= minAngle ? maxAngle - minAngle : 360 - minAngle + maxAngle;
  } else {
    angleDifference =
      minAngle >= maxAngle ? minAngle - maxAngle : 360 - maxAngle + minAngle;
  }

  let currentAngle = isClockwise
    ? minAngle + normalizedQuality * angleDifference
    : minAngle - normalizedQuality * angleDifference;

  currentAngle = (currentAngle + 360) % 360;

  return currentAngle;
};
