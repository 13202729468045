import { useEffect, useState } from "react";
import { useEnv } from "../../../../shared/hooks/useEnv/useEnv";
import { IMAGES, loadMocks } from "../../helpers/loadMocks/loadMocks";

export function useMock() {
  const { isDevelopment } = useEnv();
  const [mockFiles, setMockFiles] = useState<File[]>([]);

  useEffect(() => {
    if (!isDevelopment) return;

    const processMocks = async () => {
      const images = await loadMocks(IMAGES);
      const filesMocks = await Promise.all(
        images.map(async (file, index) => {
          const response = await fetch(file);
          const blob = await response.blob();

          return new File([blob], `img${index}.webp`, { type: "image/webp" });
        })
      );

      setMockFiles(filesMocks);
    };

    processMocks();
  }, [isDevelopment]);

  return { mockFiles, isDevelopment };
}
