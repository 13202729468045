import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useLogger } from "../../../Logging";
import styles from "./ValueField.module.css";
import { ThemeContext } from "../../../../shared/context/theme/ThemeContext";
import { getClassName } from "../../../../shared/helpers/getClassName";

interface ValueFieldProps {
  title: string;
  value: { [key: string]: number };
  onChange: (value: { [key: string]: number }) => void;
}

export default function ValueField({
  title,
  value,
  onChange,
}: ValueFieldProps) {
  const { addLog } = useLogger();
  const name = Object.keys(value)[0];
  const prevValue = useRef(value[name]);
  const isDark = useContext(ThemeContext) === "dark" ? true : false;

  useEffect(() => {
    prevValue.current = value[name];
  }, [name, value]);

  const handleChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    addLog(`${name} is changed from ${prevValue.current} to ${e.target.value}`);
    onChange({ [name]: parseFloat(e.target.value) });
  };

  const handleDecrease = () => {
    const newValue = value[name] - 1;
    addLog(`${name} is decreased from ${prevValue.current} to ${newValue}`);
    onChange({ [name]: newValue });
  };

  const handleIncrease = () => {
    const newValue = value[name] + 1;
    addLog(`${name} is increased from ${prevValue.current} to ${newValue}`);
    onChange({ [name]: newValue });
  };

  const getCss = useCallback(
    (value: string) => {
      return getClassName(styles, value, isDark);
    },
    [isDark]
  );

  return (
    <>
      <p className={styles.title}>{title}</p>
      <div className={styles["section-input"]}>
        <div className={getCss("button")} onClick={handleDecrease}>
          -
        </div>
        <input
          type="number"
          name={name}
          id={name}
          onChange={handleChanges}
          value={value[name]}
        />
        <div className={getCss("button")} onClick={handleIncrease}>
          +
        </div>
      </div>
    </>
  );
}
