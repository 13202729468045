import React from "react";
import SourceImages from "../source/SourceImages";
import ResultImages from "../result/ResultImages";
import styles from "./ImageDisplay.module.css";

export default function ImageDisplay() {
  return (
    <div className={styles.gallery}>
      <SourceImages />
      <ResultImages />
    </div>
  );
}
