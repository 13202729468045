import React, { useCallback, useEffect, useLayoutEffect } from "react";
import logger, { Log, LogLevels } from "../helpers/Logger";
import styles from "./Details.module.css";
import { Button } from "../../../shared/Button/Button";
import { Divider } from "../../../shared/Devider";
import useTheme from "../../../shared/hooks/useTheme/useTheme";
import { useEnv } from "../../../shared/hooks/useEnv/useEnv";
import { useDispatch, useSelector } from "react-redux";
import { isMockImages } from "../../../redux/selectors/isDevelopment";
import { setMockImages } from "../../../redux/reducers/development";

export default function Details() {
  const [logs, setLogs] = React.useState<Log[]>([]);
  const newLogs = useCallback((logs: Log[]) => setLogs(logs), []);
  const listRef = React.useRef<HTMLDivElement | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, switchTheme] = useTheme();
  const { isDevelopment } = useEnv();

  const dispatch = useDispatch();
  const isMock = useSelector(isMockImages);
  const switchMockImages = () => {
    logger.addLog(`Mock Gallery: ${!isMock}`, "warn");
    dispatch(setMockImages(!isMock));
  };

  useEffect(() => {
    if (isDevelopment) logger.addLog("Development Mode: ON", "warn");
  }, [isDevelopment]);

  useEffect(() => {
    logger.subscribe(newLogs);
    logger.addLog("Details component is mounted");

    return () => {
      logger.addLog("Details component is unmounted", "error");
      logger.unsubscribe(newLogs);
    };
  }, [newLogs]);

  useLayoutEffect(() => {
    if (listRef.current) {
      listRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [logs]);

  return (
    <>
      <div className={styles["details__container"]}>
        {logs.map((log, index) => (
          <p
            key={`${log.timestamp}_${index}`}
            className={
              log.level === LogLevels.info
                ? styles.info
                : log.level === LogLevels.warn
                ? styles.warn
                : styles.error
            }
          >
            {log.message}
          </p>
        ))}
        <div style={{ marginTop: "auto" }} ref={listRef} />
        <Divider />
        <Button onClick={() => logger.printToConsole()}>
          Print to Console
        </Button>
        <Button onClick={switchTheme}>Switch Mode</Button>
        {isDevelopment && (
          <>
            <p className={styles.warn} style={{ textAlign: "center" }}>
              {`Development Mode: ${isMock ? "ON" : "OFF"}`}
            </p>
            <Button onClick={switchMockImages}>Mock Gallery</Button>
          </>
        )}
      </div>
    </>
  );
}
