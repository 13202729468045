export class Observable {
  private subscribers: Function[];

  constructor() {
    this.subscribers = [];
  }

  public subscribe(callback: Function) {
    this.subscribers.push(callback);
  }

  public unsubscribe(callback: Function) {
    this.subscribers = this.subscribers.filter((sub) => sub !== callback);
  }

  public broadcastUpdate(data: unknown) {
    this.subscribers.forEach((callback) => callback(data));
  }
}
