import { createSlice } from "@reduxjs/toolkit";

export const processSlice = createSlice({
  name: "process",
  initialState: {
    result: [] as File[],
  },
  reducers: {
    setResultImages: (state, action) => {
      state.result = action.payload;
    },
  },
});

export const { setResultImages } = processSlice.actions;
