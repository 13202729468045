/**
 * Get the environment of the application
 * @returns { isProduction: boolean; isTest: boolean; isDevelopment: boolean } - Object with the environment flags of the application
 */
export function getEnv(): { isProduction: boolean; isTest: boolean; isDevelopment: boolean } {
  const isDevelopment = process.env.NODE_ENV === "development";
  const isProduction = process.env.NODE_ENV === "production";
  const isTest = process.env.NODE_ENV === "test";

  return { isDevelopment, isProduction, isTest };
}
