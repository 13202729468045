import React, {useLayoutEffect, useMemo, useRef} from "react";
import {fileSize, FileSizeUnits} from "../../helpers/fileSize/fileSize";
import styles from "./Image.module.css";

export function Image({file, gridStyle}: {file: File, gridStyle?: number}) {
    const imgRef = useRef<HTMLImageElement | null>(null);

    useLayoutEffect(() => {
        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if(imgRef.current) {
                    const { width, height } = entry.contentRect;
                    const calcWidth = Math.round(width);
                    const calcHeight = Math.round(height);
                    const isRatioNotEqual = calcHeight !== calcWidth;
                    const isDifferenceBig = Math.abs(calcHeight - calcWidth) > 0;

                    if(isRatioNotEqual && isDifferenceBig) {
                        requestAnimationFrame(() => {
                            if(imgRef.current) {
                                imgRef.current.style.height = calcWidth + 'px';
                            }
                        });
                    }
                }
            }
        });

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        return () => observer.disconnect();
    }, []);

    const size = useMemo(() => {
        return fileSize(file.size, FileSizeUnits.KB);
    }, [file.size]);

    const countElementsClass = useMemo(() => {
        switch (gridStyle) {
            case 1:
                return styles.container_grid_1;
            case 2:
                return styles.container_grid_2;
            case 3:
                return styles.container_grid_3;
            case 4:
                return styles.container_grid_4;
            default:
                return styles.container_grid_4;
        }
    }, [gridStyle]);

    return (
        <figure className={`${styles.container} ${countElementsClass}`}>
            <img
                className={styles.image}
                style={{height: "auto"}}
                ref={imgRef}
                key={file.name}
                src={URL.createObjectURL(file)}
                alt={file.name}
            />
            <figcaption className={styles.description}>{size}</figcaption>
        </figure>
    )
}