import React, { CSSProperties } from "react";
import styles from "./Divider.module.css";

export default function Divider({ style }: { style?: CSSProperties }) {
  return (
    <>
      <div className={styles["neumorphic-divider"]} style={style}></div>
    </>
  );
}
