export enum ScaleModeEnum {
  standardClockwise = "standardClockwise",
  standardCounterClockwise = "standardCounterClockwise",
  invertedClockwise = "invertedClockwise",
  invertedCounterClockwise = "invertedCounterClockwise",
}

type ScaleMode =
  | "standardClockwise"
  | "standardCounterClockwise"
  | "invertedClockwise"
  | "invertedCounterClockwise";

/**
 * Calculate quality based on rotation angle.
 *
 * @param currentAngle - Current angle of the dial.
 * @param minAngle - Minimum angle of the scale.
 * @param maxAngle - Maximum angle of the scale.
 * @param minQuality - Minimum quality value.
 * @param maxQuality - Maximum quality value.
 * @param scaleMode -
 *   'standardClockwise': Standard dial, min to max in clockwise.
 *   'standardCounterClockwise': Standard dial, min to max in counter-clockwise.
 *   'invertedClockwise': Inverted dial, min to max in clockwise.
 *   'invertedCounterClockwise': Inverted dial, min to max in counter-clockwise.
 * @returns Calculated quality based on the rotation.
 */
export function calculateQualityFromRotation(
  currentAngle: number,
  minAngle: number,
  maxAngle: number,
  minQuality: number,
  maxQuality: number,
  scaleMode: ScaleMode = "standardClockwise"
) {
  let normalizedAngle = currentAngle - minAngle;

  if (normalizedAngle < 0) normalizedAngle += 360;
  if (normalizedAngle >= 360) normalizedAngle %= 360;

  let totalAngleRange = 0;
  let rotationPercentage = 0;

  switch (scaleMode) {
    case "standardClockwise":
      if (maxAngle >= minAngle) {
        totalAngleRange = maxAngle - minAngle;
      } else {
        totalAngleRange = 360 - minAngle + maxAngle;
      }

      rotationPercentage =
        Math.round((normalizedAngle / totalAngleRange) * 10000) / 10000;

      if (rotationPercentage < 0) rotationPercentage += 1;
      if (rotationPercentage > 1) rotationPercentage -= 1;

      break;
    case "standardCounterClockwise":
      let startAngleSCCW = minAngle;
      let endAngleSCCW = maxAngle === 0 ? 360 : maxAngle;

      totalAngleRange = startAngleSCCW - endAngleSCCW;
      if (totalAngleRange < 0) totalAngleRange += 360;

      rotationPercentage = (currentAngle - startAngleSCCW) / totalAngleRange;
      rotationPercentage = 1 - rotationPercentage;
      rotationPercentage = (rotationPercentage + 1) % 1;

      break;
    case "invertedClockwise":
      let invertedStartAngle = minAngle === 360 ? 0 : minAngle;
      let invertedEndAngle = maxAngle === 0 ? 360 : maxAngle;

      totalAngleRange = invertedEndAngle - invertedStartAngle;
      rotationPercentage = currentAngle / totalAngleRange;

      break;
    case "invertedCounterClockwise":
      let invertedStartAngleCCW = maxAngle === 0 ? 360 : maxAngle;
      let invertedEndAngleCCW = minAngle === 360 ? 0 : minAngle;

      totalAngleRange = invertedEndAngleCCW - invertedStartAngleCCW;
      if (totalAngleRange < 0) totalAngleRange += 360;

      rotationPercentage =
        (currentAngle - invertedStartAngleCCW) / totalAngleRange;
      rotationPercentage = (rotationPercentage + 1) % 1;

      break;
  }

  if (rotationPercentage < 0) {
    rotationPercentage = rotationPercentage + 1;
  } else if (rotationPercentage > 1) {
    rotationPercentage = rotationPercentage % 1;
  }

  const qualityRange = maxQuality - minQuality;
  const quality = minQuality + rotationPercentage * qualityRange;

  return quality;
}
