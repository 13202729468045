import { createSlice } from "@reduxjs/toolkit";
import { MimeTypesEnum } from "upload-image-converter-commercial";

export const configurationSlice = createSlice({
  name: "configuration",
  initialState: {
    quality: 0.95,
    format: MimeTypesEnum.WEBP,
    width: 500,
    height: 500,
  },
  reducers: {
    setQuality: (state, action) => {
      state.quality = action.payload;
    },
    setFormat: (state, action) => {
      state.format = action.payload;
    },
    setWidth: (state, action) => {
      state.width = action.payload;
    },
    setHeight: (state, action) => {
      state.height = action.payload;
    },
  },
});

export const { setQuality, setFormat, setWidth, setHeight } =
  configurationSlice.actions;
