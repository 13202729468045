export const calculateAdjustedRotation = (
  initialRotation: number,
  startAngle: number,
  newAngle: number,
  minAngle: number,
  maxAngle: number
) => {
  let angleDiff = newAngle - startAngle;

  if (angleDiff < -180) angleDiff += 360;
  else if (angleDiff > 180) angleDiff -= 360;

  let adjustedRotation = (initialRotation + angleDiff + 360) % 360;

  if (maxAngle < minAngle) {
    if (adjustedRotation > maxAngle && adjustedRotation < minAngle) {
      adjustedRotation =
        adjustedRotation > (minAngle + maxAngle) / 2 ? minAngle : maxAngle;
    }
  } else {
    if (adjustedRotation < minAngle) adjustedRotation = minAngle;
    if (adjustedRotation > maxAngle) adjustedRotation = maxAngle;
  }

  return adjustedRotation;
};
