import React from "react";
import styles from "./Footer.module.css";

export default function Footer() {
  return (
      <footer>
          <div>Images Converter @ beta |</div>
          <div><a className={styles.lib} href="https://www.npmjs.com/package/upload-images-converter">✓ powered by library</a></div>
          <div>|</div>
          <div><a className={styles.link} href="https://hydrosoft.uk">✓ hydrosoft.uk</a></div>
      </footer>
  );
}
