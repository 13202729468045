import React, { forwardRef, useCallback, useContext } from "react";
import styles from "./ContentCard.module.css";
import { ThemeContext } from "../../../context/theme/ThemeContext";
import { getClassName } from "../../../helpers/getClassName";

export const ContentCard = forwardRef(function ContentCard(
  {
    height,
    children,
    style,
  }: {
    height?: string;
    children: React.ReactNode;
    style?: React.CSSProperties;
  },
  ref: React.Ref<HTMLDivElement>
) {
  const isDark = useContext(ThemeContext) === "dark" ? true : false;
  const getCss = useCallback(
    (value: string) => {
      return getClassName(styles, value, isDark);
    },
    [isDark]
  );

  return (
    <section className={getCss("card")} style={{ ...style, height }} ref={ref}>
      {children}
    </section>
  );
});
