import React, { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setNonValidFiles,
  setSourceFiles,
  setValidFiles,
} from "../../redux/reducer";
import { checkAllowedFiles } from "../../helpers/checkAllowedFiles";
import { uploadSelector } from "../../redux/selectors";
import { setResultImages } from "../../../ImageProcess/redux/reducer";
import { useLogger } from "../../../Logging";
import styles from "./ImagesDropzone.module.css";
import { ThemeContext } from "../../../../shared/context/theme/ThemeContext";
import { getClassName } from "../../../../shared/helpers/getClassName";

export default function ImagesDropzone() {
  const dispatch = useDispatch();
  const { source } = useSelector(uploadSelector);
  const { addLog } = useLogger();
  const isDark = useContext(ThemeContext) === "dark" ? true : false;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const getCss = useCallback(
    (value: string) => {
      return getClassName(styles, value, isDark);
    },
    [isDark]
  );

  const checkFiles = useCallback(() => {
    if (!source) {
      return;
    }

    const { valid, nonvalid } = checkAllowedFiles(source);

    dispatch(setValidFiles(valid));
    dispatch(setNonValidFiles(nonvalid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source, dispatch]);

  useEffect(() => {
    checkFiles();
  }, [checkFiles, source]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const files = e.target.files;

    dispatch(setSourceFiles(files));
    addLog("Files are selected");

    dispatch(setResultImages([]));
    addLog("Result gallary is cleared");
  };

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer.files;

    dispatch(setSourceFiles(files));
    addLog("Files dropped and selected");

    dispatch(setResultImages([]));
    addLog("Result gallary is cleared");
  };

  return (
    <>
      <div
        className={getCss("dragdrop")}
        onClick={handleClick}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <p>Drop image here</p>
        <p>or</p>
        <p>click to select</p>
        <input
          type="file"
          multiple
          onChange={handleChange}
          style={{ display: "none" }}
          ref={inputRef}
        />
      </div>
    </>
  );
}
