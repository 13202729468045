export function calculateIndicatorPositions(
  minAngle: number,
  maxAngle: number,
  quantity: number,
  isClockwise: boolean
): [number[], number] {
  const positions = [];
  const range = isClockwise
    ? (maxAngle - minAngle + 360) % 360
    : (minAngle - maxAngle + 360) % 360;

  const step = range / (quantity - 1);

  for (let i = 0; i < quantity; i++) {
    const angle = isClockwise
      ? (minAngle + step * i) % 360
      : (minAngle - step * i + 360) % 360;
    positions.push(angle);
  }

  return [positions, step];
}
