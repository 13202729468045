import React from "react";
import styles from "./Button.module.css";

export function Button({
  children,
  onClick,
  disabled,
}: {
  children?: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
}) {
  return (
    <>
      <button className={styles.button} disabled={disabled} onClick={onClick}>
        {children}
      </button>
    </>
  );
}
