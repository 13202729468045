import React from "react";
import { useSelector } from "react-redux";
import { uploadSelector } from "../../../ImagesUpload";
import Gallery from "../gallary/Gallery";
import { useMock } from "../../hooks/useMock/useMock";
import { isMockImages } from "../../../../redux/selectors/isDevelopment";

export default function SourceImages() {
  const { valid } = useSelector(uploadSelector);
  const isMock = useSelector(isMockImages);
  const { mockFiles, isDevelopment } = useMock();

  return <Gallery files={isDevelopment && isMock ? mockFiles : valid} />;
}
