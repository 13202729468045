import { useCallback } from "react";
import logger, { LogLevels } from "../helpers/Logger";

export const useLogger = () => {
  const addLog = useCallback(
    (
      message: string,
      level: LogLevels | keyof typeof LogLevels = LogLevels.info
    ) => logger.addLog(message, level),
    []
  );
  return { addLog };
};
