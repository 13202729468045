import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const developmentSlice = createSlice({
  name: "development",
  initialState: {
    isDevelopment: true,
    isMockImages: true,
  },
  reducers: {
    setDevelopment: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isDevelopment: action.payload,
      };
    },
    setMockImages: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isMockImages: action.payload,
      };
    },
  },
});

export const { setDevelopment, setMockImages } = developmentSlice.actions;
