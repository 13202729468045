import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./QualityKnob.module.css";
import { useDispatch, useSelector } from "react-redux";
import { selectConfiguration } from "../../redux/selectors";
import { useLogger } from "../../../Logging";
import { setQuality } from "../../redux/reducer";
import { calculateIndicatorPositions } from "./helpers/calculateIndicatorPositions/calculateIndicatorPositions";
import { calculateCurrentAngle } from "./helpers/calculateCurrentAngle/calculateCurrentAngle";
import { calculateQualityFromRotation } from "./helpers/calculateQualityFromRotation/calculateQualityFromRotation";
import { calculateAdjustedRotation } from "./helpers/calculateAdjustedRotation/calculateAdjustedRotation";
import { calculateMouseAngleRelativeToCenter } from "./helpers/calculateMouseAngleRelativeToCenter/calculateMouseAngleRelativeToCenter";
import { QualityLights } from "../QualityLights/QualityLights";

export default function QualityKnob() {
  const dispatch = useDispatch();
  const { quality } = useSelector(selectConfiguration);
  const { addLog } = useLogger();

  const quantity = 96;
  const minAngle = 240;
  const maxAngle = 120;
  const isClockwise = true;

  const [rotation, setRotation] = useState(0);
  const divToRotateRef = useRef<HTMLDivElement>(null);
  const startAngle = useRef(0);

  useEffect(() => {
    let currentAngle = calculateCurrentAngle(
      quality,
      minAngle,
      maxAngle,
      isClockwise
    );
    currentAngle = Math.round(currentAngle);

    setRotation(currentAngle);
  }, [isClockwise, quality, minAngle, maxAngle]);

  const componentQuality = useRef(quality);

  const [indicatorPositions, step] = useMemo(
    () =>
      calculateIndicatorPositions(minAngle, maxAngle, quantity, isClockwise),
    [minAngle, maxAngle, quantity, isClockwise]
  );

  const activeIndex = useMemo(
    () =>
      indicatorPositions.findIndex((angle) => {
        return Math.abs(angle - rotation) < step / 2;
      }),
    [indicatorPositions, rotation, step]
  );

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    if (divToRotateRef.current) {
      startAngle.current = calculateMouseAngleRelativeToCenter(
        event.clientX,
        event.clientY,
        divToRotateRef.current
      );
      startAngle.current = Math.round(startAngle.current);

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (divToRotateRef.current) {
      const newAngle = calculateMouseAngleRelativeToCenter(
        event.clientX,
        event.clientY,
        divToRotateRef.current
      );

      let adjustedRotation = calculateAdjustedRotation(
        rotation,
        startAngle.current,
        newAngle,
        minAngle,
        maxAngle
      );

      adjustedRotation = Math.round(adjustedRotation);

      if (adjustedRotation !== rotation) {
        setRotation(adjustedRotation);

        const newQuality = calculateQualityFromRotation(
          adjustedRotation,
          minAngle,
          maxAngle,
          0.1,
          1
        );

        componentQuality.current = Math.round(newQuality * 100) / 100;
      }
    }
  };

  const handleMouseUp = () => {
    if (quality !== componentQuality.current) {
      addLog(
        `Quality is changed from ${quality} to ${componentQuality.current}`
      );
      dispatch(setQuality(componentQuality.current));
    }

    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  return (
    <>
      <div className={styles["case"]}>
        {indicatorPositions.map((angle, index) => (
          <div
            key={index}
            className={`${styles.mark} ${
              index <= activeIndex ? styles.selected : "#000"
            }`}
            style={{
              transform: `rotate(${angle}deg) translateY(-85px)`,
            }}
          ></div>
        ))}
        <div
          className={styles["knob"]}
          ref={divToRotateRef}
          onMouseDown={handleMouseDown}
          style={{ transform: `rotate(${rotation}deg)` }}
        >
          <div className={styles["indicator"]}></div>
          <div
            style={{ transform: `rotate(-${rotation}deg)`, userSelect: "none" }}
            className={styles["indicator-text"]}
          >
            {componentQuality.current}
          </div>
        </div>
      </div>
      <QualityLights
        quality={componentQuality.current}
        mediumLow={0.525}
        mediumHigh={0.9}
        quantity={9}
      />
    </>
  );
}
