import React, { useContext, useEffect, useState } from "react";
import styles from "./QualityLights.module.css";
import { getClassName } from "../../../../shared/helpers/getClassName";
import { ThemeContext } from "../../../../shared/context/theme/ThemeContext";

enum Quality {
  Low = "low",
  Medium = "medium",
  High = "high",
}

function calculateActiveLeds(
  quality: number,
  totalLeds: number,
  minQuality: number,
  maxQuality: number
) {
  const normalizedQuality = (quality - minQuality) / (maxQuality - minQuality);

  return Math.round(normalizedQuality * totalLeds);
}

export function QualityLights({
  quality,
  mediumLow,
  mediumHigh,
  quantity = 3,
}: {
  quality: number;
  mediumLow: number;
  mediumHigh: number;
  quantity?: number;
}) {
  const [light, setLight] = React.useState<Quality>(Quality.Low);
  const [activeLeds, setActiveLeds] = useState<Array<boolean>>([]);
  const isDark = useContext(ThemeContext) === "dark" ? true : false;

  useEffect(() => {
    const activeCount = calculateActiveLeds(quality, quantity, 0.1, 1);

    setActiveLeds(Array.from({ length: quantity }, (_, i) => i < activeCount));
  }, [quality, quantity]);

  useEffect(() => {
    if (quality < mediumLow) {
      setLight(Quality.Low);
    } else if (quality > mediumLow && quality < mediumHigh) {
      setLight(Quality.Medium);
    } else if (quality > mediumHigh) {
      setLight(Quality.High);
    }
  }, [mediumHigh, mediumLow, quality]);

  return (
    <div className={styles["ledBox"]}>
      {activeLeds.map((isActive, index) => (
        <div
          key={index}
          className={`${styles["led"]} ${styles[light]}
            ${getClassName(styles, light, isDark)}
            ${isActive ? "" : styles["off"]}
            `}
        ></div>
      ))}
    </div>
  );
}
