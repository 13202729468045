export const calculateMouseAngleRelativeToCenter = (
  clientX: number,
  clientY: number,
  element: HTMLDivElement
) => {
  const elementRect = element.getBoundingClientRect();
  const x = clientX - elementRect.left - elementRect.width / 2;
  const y = clientY - elementRect.top - elementRect.height / 2;
  const angle = Math.atan2(y, x) * (180 / Math.PI);

  return angle < 0 ? angle + 360 : angle;
};
