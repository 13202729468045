import { useCallback, useEffect, useState } from "react";
import { useLogger } from "../../../features/Logging";

export default function useCustomTheme(): [string, () => void] {
  const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)");
  const [theme, setTheme] = useState(mediaQueryList.matches ? "dark" : "light");
  const { addLog } = useLogger();
  const root = document.documentElement;

  const handleDarkMode = useCallback(() => {
    addLog(`Theme vars applied to dark`);

    root.style.setProperty("--base-color", "rgba(25, 50, 51, 1.0)");
    root.style.setProperty("--background-light", "rgba(27, 54, 55, 1.0)");
    root.style.setProperty("--background-dark", "rgba(23, 45, 46, 1.0)");
    root.style.setProperty("--shadow-light", "rgba(21, 43, 43, 1.0)");
    root.style.setProperty("--shadow-dark", "rgba(29, 58, 59, 1.0)");
    root.style.setProperty("--text-color", "rgba(255, 255, 255, 1)");
    root.style.setProperty("--text-alarmed", "rgba(232, 62, 62, 1.0)");
    root.style.setProperty("--text-warning", "rgba(245, 166, 35, 1.0)");
    root.style.setProperty("--text-info", "rgba(0, 123, 255, 1.0)");
  }, [addLog, root.style]);

  const handleLightMode = useCallback(() => {
    addLog(`Theme vars applied to light`);

    root.style.setProperty("--base-color", "rgba(240, 240, 240, 1.0)");
    root.style.setProperty("--background-light", "rgba(246, 246, 246, 1)");
    root.style.setProperty("--background-dark", "rgba(220, 220, 220, 1)");
    root.style.setProperty("--shadow-light", "rgba(255, 255, 255, 0.5)");
    root.style.setProperty("--shadow-dark", "rgba(214, 214, 214, 0.5)");
    root.style.setProperty("--text-color", "rgba(0, 0, 0, 1.0)");

    root.style.setProperty("--text-alarmed", "rgba(192, 10, 195, 1.0)");
    root.style.setProperty("--text-warning", "rgba(19, 105, 215, 1.0)");
    root.style.setProperty("--text-info", "rgba(3, 20, 51, 1.0)");
  }, [addLog, root.style]);

  const changeTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    addLog(`Theme is changed from ${theme} to ${newTheme} by user`);
    setTheme(newTheme);
  };

  useEffect(() => {
    const themeHandler = (e: MediaQueryListEvent) => {
      const newTheme = e.matches ? "dark" : "light";
      addLog(`Theme is changed from ${theme} to ${newTheme} by media query`);
      setTheme(newTheme);
    };

    mediaQueryList.addEventListener("change", themeHandler);

    return () => mediaQueryList.removeEventListener("change", themeHandler);
  }, [addLog, mediaQueryList, theme]);

  useEffect(() => {
    const isDarkTheme = theme === "dark";

    if (isDarkTheme) {
      handleDarkMode();
    } else {
      handleLightMode();
    }
  }, [theme, handleLightMode, handleDarkMode]);

  return [theme, changeTheme];
}
