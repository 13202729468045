import { combineSlices } from "@reduxjs/toolkit";
import { uploadSlice } from "../../features/ImagesUpload/redux/reducer";
import { configurationSlice } from "../../features/ConverterConfiguration/redux/reducer";
import { processSlice } from "../../features/ImageProcess/redux/reducer";
import { getEnv } from "../../shared/helpers/getEnv";
import { developmentSlice } from "./development";

const { isDevelopment } = getEnv();

export const rootReducer = combineSlices(
  uploadSlice,
  configurationSlice,
  processSlice
);

if (isDevelopment) {
  rootReducer.inject(developmentSlice);
}

export default rootReducer;
