import React from "react";
import { useLogger } from "../../../Logging";
import { MimeTypesEnum } from "upload-image-converter-commercial";
import { setFormat } from "../../redux/reducer";
import { useDispatch, useSelector } from "react-redux";
import { selectConfiguration } from "../../redux/selectors";
import styles from "./ValueSelector.module.css";

interface ValueSelectorProps {
  title: string;
}

export default function ValueSelector({ title }: ValueSelectorProps) {
  const { addLog } = useLogger();
  const dispatch = useDispatch();
  const { format } = useSelector(selectConfiguration);

  const handleFormatChange = (newFormat: MimeTypesEnum) => {
    addLog(`Format is changed from ${format} to ${newFormat}`);
    dispatch(setFormat(newFormat));
  };

  return (
    <>
      <ul aria-label="format" className={styles.select}>
        {Object.entries(MimeTypesEnum).map(([kay, value]) => (
          <li
            key={value}
            className={`${styles.option} ${
              value === format ? styles.selected : undefined
            }`}
            onClick={() => handleFormatChange(value)}
          >
            {kay}
          </li>
        ))}
      </ul>
    </>
  );
}
