const loadImage = (imageName: string) => {
  return import(`./../../mocks/images/${imageName}.webp`)
    .then((module) => module.default)
    .catch((error) => {
      console.error(`Не удалось загрузить изображение: ${imageName}`, error);
    });
};

export const loadMocks = async (images: string[]) => {
  const imagesData = await Promise.all(images.map(loadImage));

  return imagesData;
};

export const IMAGES = [
  "img1",
  "img2",
  "img3",
  "img4",
  "img5",
  "img6",
  "img7",
  "img8",
  "img9",
  "img10",
];
