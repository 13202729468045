export enum FileSizeUnits {
  B = "B",
  KiB = "KiB",
  KB = "KB",
  MiB = "MiB",
  MB = "MB",
  GiB = "GiB",
  GB = "GB",
  TiB = "TiB",
  TB = "TB",
}

export function fileSize(size: number, toUnit: FileSizeUnits): string {
  if (size === 0) {
    return `0 ${FileSizeUnits[toUnit]}`;
  }

  switch (toUnit) {
    case FileSizeUnits.B:
      return `${size} ${FileSizeUnits[toUnit]}`;
    case FileSizeUnits.KiB:
      return `${(size / 1024).toFixed(2)} ${FileSizeUnits[toUnit]}`;
    case FileSizeUnits.KB:
      return `${(size / 1000).toFixed(2)} ${FileSizeUnits[toUnit]}`;
    case FileSizeUnits.MiB:
      return `${(size / Math.pow(1024, 2)).toFixed(2)} ${
        FileSizeUnits[toUnit]
      }`;
    case FileSizeUnits.MB:
      return `${(size / Math.pow(1000, 2)).toFixed(2)} ${
        FileSizeUnits[toUnit]
      }`;
    case FileSizeUnits.GiB:
      return `${(size / Math.pow(1024, 3)).toFixed(2)} ${
        FileSizeUnits[toUnit]
      }`;
    case FileSizeUnits.GB:
      return `${(size / Math.pow(1000, 3)).toFixed(2)} ${
        FileSizeUnits[toUnit]
      }`;
    case FileSizeUnits.TiB:
      return `${(size / Math.pow(1024, 4)).toFixed(2)} ${
        FileSizeUnits[toUnit]
      }`;
    case FileSizeUnits.TB:
      return `${(size / Math.pow(1000, 4)).toFixed(2)} ${
        FileSizeUnits[toUnit]
      }`;
    default:
      return "Unknown unit";
  }
}
