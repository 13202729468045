export function getClassName(
  styles: { [key: string]: string },
  cssClass: string,
  isDark: boolean
) {
  const base = styles[cssClass];
  const dark = isDark ? styles[`${cssClass}Dark`] : "";

  return `${base} ${dark}`;
}
