import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import imageConverter from "upload-image-converter-commercial";
import { setResultImages } from "../../redux/reducer";
import { selectConfiguration } from "../../../ConverterConfiguration/redux/selectors";
import { uploadSelector } from "../../../ImagesUpload";
import { useLogger } from "../../../Logging";
import { Button } from "../../../../shared/Button/Button";

export default function Process() {
  const configuration = useSelector(selectConfiguration);
  const { quality, format, width, height } = configuration;
  const { valid } = useSelector(uploadSelector);
  const dispatch = useDispatch();
  const { addLog } = useLogger();
  const isEnabled = useMemo(() => !(valid && valid.length > 0), [valid]);

  useEffect(() => {
    addLog(`Process button is ${isEnabled ? "disabled" : "enabled"}`, "warn");
  }, [addLog, isEnabled]);

  const process = () => {
    addLog("Process started", "warn");
    imageConverter({
      files: valid,
      quality: quality,
      format: format,
      width: width,
      height: height,
    }).then((result) => {
      addLog("Process finished");
      dispatch(setResultImages(result));
    });
  };

  return (
    <>
      <Button onClick={process} disabled={isEnabled}>
        Process!
      </Button>
    </>
  );
}
