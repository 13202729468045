import React, { useEffect, useRef } from "react";
import "./Panel.css";
import { useDispatch, useSelector } from "react-redux";
import { setHeight, setWidth } from "../../redux/reducer";
import { selectConfiguration } from "../../redux/selectors";

import ValueField from "../ValueField/ValueField";
import ValueSelector from "../ValueSelector/ValueSelector";
import { Divider } from "../../../../shared/Devider";
import QualityKnob from "../QualityKnob/QualityKnob";

export default function Panel() {
  const { quality, format, width, height } = useSelector(selectConfiguration);
  const dispatch = useDispatch();

  const prevWidth = useRef(width);
  const prevHeight = useRef(height);

  useEffect(() => {
    prevWidth.current = width;
    prevHeight.current = height;
  }, [quality, format, width, height]);

  return (
    <>
      <QualityKnob />
      <Divider />
      <ValueSelector title="Format" />
      <Divider />
      <ValueField
        title="Width"
        value={{ width }}
        onChange={(value) => dispatch(setWidth(value.width))}
      />
      <ValueField
        title="Height"
        value={{ height }}
        onChange={(value) => dispatch(setHeight(value.height))}
      />
    </>
  );
}
