import React from "react";
import style from "./App.module.css";
import ImagesDropzone from "./features/ImagesUpload/components/ImagesDropzone/ImagesDropzone";

import Details from "./features/Logging/details/Details";
import Footer from "./footer/Footer";
import Panel from "./features/ConverterConfiguration/components/panel/Panel";
import Process from "./features/ImageProcess/components/process/Process";
import { ImageDisplay } from "./features/ImagesDisplay";
import { ContentCard } from "./shared/ContentCard";
import { Divider } from "./shared/Devider";
import useTheme from "./shared/hooks/useTheme/useTheme";
import { ThemeContext } from "./shared/context/theme/ThemeContext";

function App() {
  const [theme] = useTheme();
  const panelRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <>
      <ThemeContext.Provider value={theme}>
        <main>
          <aside
            className={style.logs}
            style={{
              maxHeight: panelRef.current?.offsetHeight + "px",
            }}
          >
            <ContentCard style={{ flexGrow: 1, maxWidth: "min-content" }}>
              <Details />
            </ContentCard>
          </aside>
          <section>
            <ContentCard>
              <ImagesDropzone />
            </ContentCard>
            <ImageDisplay />
          </section>
          <aside>
            <ContentCard ref={panelRef}>
              <Panel />
              <Divider />
              <Process />
            </ContentCard>
          </aside>
        </main>
        <Footer />
      </ThemeContext.Provider>
    </>
  );
}

export default App;
