import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const uploadSlice = createSlice({
  name: "upload",
  initialState: {
    source: null as FileList | null,
    valid: [] as File[],
    nonvalid: [] as string[],
  },
  reducers: {
    setSourceFiles: (state, action: PayloadAction<FileList | null>) => {
      state.source = action.payload;
    },
    setValidFiles: (state, action: PayloadAction<File[]>) => {
      state.valid = action.payload;
    },
    setNonValidFiles: (state, action: PayloadAction<string[]>) => {
      state.nonvalid = action.payload;
    },
  },
});

export const { setSourceFiles, setValidFiles, setNonValidFiles } =
  uploadSlice.actions;
