import { MimeTypesEnum } from "upload-image-converter-commercial";
import logger from "../../Logging/helpers/Logger";

export const checkAllowedFiles = (files: FileList | null) => {
  const nonvalid: string[] = [];
  const valid: File[] = [];
  logger.addLog("Checking files...");

  if (!files) return { nonvalid, valid };

  const allowedMimeTypes = Object.values(MimeTypesEnum).map(String);

  Array.from(files).forEach((file) => {
    if (allowedMimeTypes.includes(file.type)) {
      logger.addLog(`File ${file.name} has added`, "info");
      valid.push(file);
    } else {
      logger.addLog(`File ${file.name} has now allowed format`, "error");
      nonvalid.push(`${file.name}`);
    }
  });

  return { nonvalid, valid };
};
